import { Outlet } from 'react-router-dom';
import { lazy, Suspense } from 'react';
import { Layout as AuthModernLayout } from 'src/layouts/auth/modern-layout';
import Error401Page from 'src/pages/401';
import Error404Page from 'src/pages/404';
import Error500Page from 'src/pages/500';
import { dashboardRoutes } from './dashboard';

const LoginModernPage = lazy(() => import('src/pages/auth-demo/login/login'));
const ForgotPasswordEmailPage = lazy(() => import ('src/pages/auth-demo/login/reset'));
const ForgotPasswordOtpPage = lazy(() => import('src/pages/auth-demo/login/otp-page'));
const PasswordChange = lazy(() => import('src/pages/auth-demo/login/passwordChange'));

export const routes = [
  {
    element: (     
      <AuthModernLayout>
      <Suspense>
        <Outlet />
      </Suspense>
    </AuthModernLayout>
    ),
    children: [
      {
        index: true,
        element: <LoginModernPage />
      },
      {
        path:'forgotPassword',
        element: <ForgotPasswordEmailPage />
      },
      {
        path:'forgotPasswordotp',
        element: <ForgotPasswordOtpPage />
      },
      {
        path:'passwordChange',
        element: <PasswordChange />
      }
    ]
  },
  //...authRoutes,
  // ...authDemoRoutes,
  ...dashboardRoutes,    
  {
    path: '401',
    element: <Error401Page />
  },
  {
    path: '404',
    element: <Error404Page />
  },
  {
    path: '500',
    element: <Error500Page />
  },
  {
    path: '*',
    element: <Error404Page />
  }
];
