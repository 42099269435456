/**
 * @Author: Anandp
 * @Date:   2024-04-17 18:29:50
 * @Last Modified by:   Anandp
 * @Last Modified time: 2024-10-06 21:48:36
 */
export const amplifyConfig = {
  aws_project_region: process.env.REACT_APP_AWS_PROJECT_REGION,
  aws_cognito_identity_pool_id:
    process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
  aws_cognito_region: process.env.REACT_APP_AWS_COGNITO_REGION,
  aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id:
    process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
};

export const auth0Config = {
  base_url: process.env.REACT_APP_AUTH0_BASE_URL,
  client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
  issuer_base_url: process.env.REACT_APP_AUTH0_ISSUER_BASE_URL,
};

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
};

export const gtmConfig = {
  containerId: process.env.REACT_APP_GTM_CONTAINER_ID,
};

export const mapboxConfig = {
  apiKey: process.env.REACT_APP_MAPBOX_API_KEY,
};
export let Url = "";
export let CusUrl = "";
if (window.location.host === "dev-admin.sl-ivl.com") {
  Url = "https://1qp1gktax0.execute-api.ap-southeast-2.amazonaws.com/dev";
  CusUrl = "https://dev-customer.sl-ivl.com";
} else if (window.location.host === "dev-admin-datamigration.sl-ivl.com") {
  Url = "https://az56cn4erf.execute-api.ap-southeast-2.amazonaws.com/dev";
  CusUrl = "https://dev-customer-datamigration.sl-ivl.com";
} else if (window.location.host === "sl-load-test-admin.sl-ivl.com") {
  Url = "https://2swud8yia0.execute-api.ap-southeast-2.amazonaws.com/dev";
  //Url = "https://sl-load-test-admin.sl-ivl.com/dev"
  CusUrl = "https://sl-load-test-customer.sl-ivl.com";
} else if (window.location.host === "stage-admin.sl-ivl.com") {
  Url = "https://caelf452vf.execute-api.ap-southeast-2.amazonaws.com/stage";
  CusUrl = "https://stage-customer.sl-ivl.com";
} else if (window.location.host === "qa-admin.sl-ivl.com") {
  Url = "https://nj2xyvrk7h.execute-api.ap-southeast-2.amazonaws.com/test";
  CusUrl = "https://qa-customer.sl-ivl.com";
} else if (window.location.host === "train-admin.sl-ivl.com") {
  Url = "https://hk3z6pu5c1.execute-api.ap-southeast-2.amazonaws.com/dev";
  CusUrl = "https://train-customer.sl-ivl.com";
} else if (window.location.host === "admin-stage.sl-ivl.com") {
  Url = "https://admin-stage.sl-ivl.com/api";
  CusUrl = "https://customer-stage.sl-ivl.com";
} else if (window.location.host === "prod-admin.sl-ivl.com") {
  Url = "https://prod-api.sl-ivl.com";
  CusUrl = "https://prod-customer.sl-ivl.com";
}
else if (window.location.host === "integratedtesting-admin.sl-ivl.com") {
  Url = "https://lpy48q0ygi.execute-api.ap-southeast-2.amazonaws.com/dev";
  CusUrl = "https://integratedtesting-customer.sl-ivl.com";
} else {
  Url = "http://localhost:8081";
  CusUrl = "http://localhost:3001";
}