import { Url } from "src/config";
import axios from "axios";

axios.interceptors.request.use(
  (config) => {
    config.headers["access-token"] = `${sessionStorage.getItem('accessToken')}`;
    console.log('Request:', config);
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
const UserapiService = {
  //create a new user
  /**
   * API calling function to create new user
   * @param {object} userData
   * @returns
   */
  addUser: async (userData) => {
    try {
      let response = await axios.post(`${Url}/module/user`, userData);
      console.log("response", response);
      return response.data;
    } catch (error) {
      console.log(error.response.data.message, "error");
      return error.response.data;
    }
  },
  //pagination
  /**
   * API calling function to get list users with pagination and searching
   * @param {number} currentPage
   * @param {number} limit
   * @param {string} searchDataValue
   * @returns
   */
  userPagination: async (currentPage, limit, searchDataValue) => {
    let searchData = "";
    if (searchDataValue.length > 2) {
      searchData = `&search=${searchDataValue}`;
    }
    try {
      let response = await axios.get(
        `${Url}/module/user/paginationUser?page=${currentPage}&limit=${limit}${searchData}`
      );
      console.log(response);
      return response.data;
    } catch (error) {
      console.log(error.message);
    }
  },
  emailLogPagination: async (currentPage, limit, searchDataValue) => {
    let searchData = "";
    if (searchDataValue.length > 2) {
      searchData = `&search=${searchDataValue}`;
    }
    try {
      let response = await axios.get(
        `${Url}/module/user/paginationEmailLog?page=${currentPage}&limit=${limit}${searchData}`
      );
      console.log(response);
      return response.data;
    } catch (error) {
      console.log(error.message);
    }
  },
  editUserEmail: async (userId, email) => {
    try {
      let response = await axios.post(`${Url}/module/user/editUserEmail`, {
        userId,
        email,
      });
      return response.data;
    } catch (error) {
      console.log(error.message);
    }
  },

  /**
   * API  call to get users
   * @param {*} searchData
   * @returns
   */
  getUsers: async (searchData) => {
    console.log();
    let condition = "";

    if (searchData && searchData.length >= 3) {
      condition = `?searchData=${searchData}`;
      console.log("searching user api", condition);
    }
    try {
      const response = await axios.get(`${Url}/module/user${condition}`);
      return response.data;
    } catch (error) {}
  },
  /**
   * API calling function to get single user
   * @param {number} userId
   * @returns
   */
  getSelectedtUser: async (userId) => {
    try {
      console.log(userId);
      const response = await axios.get(`${Url}/module/user?id=${userId}`);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },

  editProfilePicture: async (adminId, pictureData) => {
    try {
      console.log("123", adminId);
      console.log(pictureData);
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const response = await axios.put(
        `${Url}/module/uploadMedia?id=${adminId}`,
        pictureData,
        config
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },
  //edit-a-user
  /**
   * API call to update a user
   * @param {number} userId
   * @param {object} editedValues
   * @returns
   */
  editUser: async (userId, editedValues) => {
    try {
      console.log("editedValues", editedValues);
      const response = await axios.put(
        `${Url}/module/user/${userId}`,
        editedValues
      );
      console.log("res", response);
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  },
  /**
   * API call to list all roles
   * @returns
   */
  getRoles: async () => {
    try {
      const response = await axios.get(`${Url}/module/user/roles`);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },
  /**
   * Fetches selected permissions for a user by their ID.
   *
   * @param {number} userId - The ID of the user for whom permissions are requested.
   * @returns {object} - An object containing the selected permissions for the user.
   */
  getSelectedPermission: async (userId) => {
    console.log(userId, "hey im userid");
    try {
      const response = await axios.get(
        `${Url}/module/user/selectedpermission?id=${userId}`
      );
      return response.data;
    } catch (error) {
      console.log(error.message);
    }
  },
  /**
   * api used to generate otp and send it to the particular email
   * @param {string} email
   * @returns
   */
  sendOtp: async (email) => {
    try {
      console.log("userApi", email);
      const response = await axios.post(`${Url}/module/forgotPasswordSendOtp`, {
        email,
      });
      console.log("api", response);
      return response.data;
    } catch (error) {
      console.log(error.message);
    }
  },
  /**
   * api used to verify the otp
   * @param {number} otp
   * @param {string} email
   * @returns
   */
  verifyOtp: async (otp, email) => {
    try {
      console.log("api", otp, email);
      const response = await axios.post(`${Url}/module/verfiyOtp`, {
        otp,
        email,
      });
      return response.data;
    } catch (error) {
      console.log(error.message);
    }
  },
  /**
   * api used to change or update the password
   * @param {string} password
   * @param {string} email
   * @returns
   */
  changePassword: async (password, email) => {
    try {
      console.log("api", password, email);
      const response = await axios.post(`${Url}/module/changePassword`, {
        password,
        email,
      });
      return response.data;
    } catch (error) {
      console.log(error.message);
    }
  },

  /**
   * Fetches paginated data for inactive users from an API with optional search functionality.
   *
   * @param {number} currentPage - The current page of data to retrieve.
   * @param {number} limit - The number of results to display per page.
   * @param {string} searchDataValue - The search query (optional).
   * @returns {object} - An object containing the paginated inactive user data and metadata.
   */
  paginatedInActiveUser: async (currentPage, limit, searchDataValue) => {
    let searchData = "";
    if (searchDataValue.length > 2) {
      searchData = `&search=${searchDataValue}`;
    }
    try {
      let response = await axios.get(
        `${Url}/module/user/paginatedInActiveUser?page=${currentPage}&limit=${limit}${searchData}`
      );
      console.log(response);
      return response.data;
    } catch (error) {
      console.log(error.message);
    }
  },
  /**
   * Fetches paginated user data from an API with optional search functionality.
   *
   * @param {number} currentPage - The current page of data to retrieve.
   * @param {number} limit - The number of results to display per page.
   * @param {string} searchDataValue - The search query (optional).
   */
  userpaginatedReport: async (currentPage, limit, searchDataValue) => {
    let searchData = "";
    console.log("search data ", searchDataValue);
    if (searchDataValue.length > 2) {
      searchData = `&search=${searchDataValue}`;
    }
    try {
      let response = await axios.get(
        `${Url}/module/user/paginatedReport?page=${currentPage}&limit=${limit}${searchData}`
      );
      console.log(response);
      return response.data;
    } catch (error) {
      console.log(error.message);
    }
  },

  getProfilePicture: async (adminId) => {
    try {
      let response = await axios.get(`${Url}/module/getMedia?id=${adminId}`);
      console.log(response);
      return response.data;
    } catch (error) {
      console.log(error.message);
    }
  },

  passwordChange: async (adminId, adminData) => {
    console.log("ad", adminId);
    try {
      let response = await axios.post(
        `${Url}/module/user/passwordChange?id=${adminId}`,
        adminData
      );
      console.log(response);
      return response.data;
    } catch (error) {
      console.log(error.message);
      return error.message;
    }
  },

  saveEmailSigContent: async (adminId, adminData) => {
    console.log("ad", adminId);
    console.log("adData", adminData);
    try {
      let response = await axios.post(
        `${Url}/module/user/saveEmailSignatureContent?id=${adminId}`,
        adminData
      );
      console.log(response);
      return response.data;
    } catch (error) {
      console.log(error.message);
      return error.message;
    }
  },

  getEmailSignatureData: async (adminId) => {
    try {
      let response = await axios.get(
        `${Url}/module/user/getEmailSignatureData?id=${adminId}`
      );
      console.log(response);
      return response.data;
    } catch (error) {
      console.log(error.message);
    }
  },
  resetPassword: async (userId, emaildata) => {
    try {
      let response = await axios.post(`${Url}/module/user/resetPassword?id=${userId}`, emaildata);
      return response.data;
    } catch (error) {
      console.log(error.message);
    }
  },
  /**
   * Upload the file from the CK editor to the S3 bucket 
   * and provide the location of the uploaded file.
   * @param {*} userId  Login User id
   * @param {*} fileData  File data
   * @returns file path
   */
  uploadCkeditorImage: async (userId, fileData) => {
    try {      
      console.log(fileData);
      const formData = new FormData();
      formData.append('file',fileData)
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const response = await axios.put(
        `${Url}/module/ckeditorUpload?id=${userId}`,
        formData,
        config
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },
};

export default UserapiService;
